import React, {
  ComponentProps,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import {
  Image,
  ImagePropsBase,
  ImageStyle,
  LayoutChangeEvent,
  LayoutRectangle,
  PixelRatio,
  StyleProp,
  View,
} from 'react-native'
import { useFragment, graphql } from 'react-relay'

import { selectBreakpointToken } from './util/selectBreakpointToken'
import { Element } from './Element'
import { TokensContext } from './context'
import { TokenValues } from './types'
import { useIsMounted } from './useIsMounted'
import { useBreakpoint } from './useBreakpoint'

import { GraphQLImage_image$key } from './__generated__/GraphQLImage_image.graphql'

export type ImageKitImageProps = {
  as?: ComponentProps<typeof Element>['as']
  children?: ReactNode
  component?: string
  onLoad?: ImagePropsBase['onLoad']
  variant?: string
  style?: StyleProp<Image>
} & UseImageKitProps

type GraphQLImageProps = {
  image: GraphQLImage_image$key
} & Omit<ImageKitImageProps, 'imageUrl'>

type ImageKitDimensions = number | 'max'

type UseImageKitProps = {
  borderRadius?: number | 'max'
  imageUrl: string
  maxHeight?: ImageKitDimensions | ImageKitDimensions[]
  maxWidth?: ImageKitDimensions | ImageKitDimensions[]
  focus?: 'auto' | 'face'
  transformations?: Record<string, string>
}
type CreateImageKitURLOptions = {
  borderRadius?: number | 'max'
  focus?: 'auto' | 'face'
  layoutDimensions?: LayoutRectangle
  maxHeight?: number | 'max'
  maxWidth?: number | 'max'
  transformations?: Record<string, string | number>
}

function useImageKit(props: UseImageKitProps) {
  const { borderRadius, focus, imageUrl, transformations } = props

  const tokens = useContext(TokensContext)
  const [layoutDimensions, setLayoutDimensions] = useState<LayoutRectangle>()
  const [imageDimensions, setImageDimensions] = useState<{
    width: number
    height: number
  } | null>(null)

  const isMounted = useIsMounted()
  const breakpoint = useBreakpoint()

  const maxHeight = selectBreakpointToken(breakpoint, props.maxHeight)
  const maxWidth = selectBreakpointToken(breakpoint, props.maxWidth)

  const imageKitURL = useMemo(
    () =>
      createImageKitURL(imageUrl, tokens, {
        borderRadius,
        focus,
        layoutDimensions,
        maxHeight,
        maxWidth,
        transformations,
      }),
    [
      borderRadius,
      focus,
      imageUrl,
      layoutDimensions,
      maxHeight,
      maxWidth,
      tokens,
      transformations,
    ]
  )

  const styles = useMemo(() => {
    const appliedStyles: StyleProp<ImageStyle> = {}

    if (maxWidth === 'max') {
      appliedStyles.width = '100%'
    } else if (typeof maxWidth === 'number') {
      appliedStyles.width = tokens.width[maxWidth]
    } else if (imageDimensions?.width) {
      appliedStyles.width = Math.ceil(imageDimensions.width / PixelRatio.get())
    }

    if (maxHeight === 'max') {
      appliedStyles.height = '100%'
    } else if (typeof maxHeight === 'number') {
      appliedStyles.height = tokens.height[maxHeight]
    } else if (
      imageDimensions?.height &&
      !(transformations?.ar && appliedStyles.width)
    ) {
      appliedStyles.height = Math.ceil(
        imageDimensions.height / PixelRatio.get()
      )
    }

    if (maxWidth) {
      if (maxWidth === 'max') {
        appliedStyles.maxWidth = '100%'
      } else {
        appliedStyles.maxWidth = tokens.width[maxWidth]
      }
    }

    if (maxHeight) {
      if (maxHeight === 'max') {
        appliedStyles.maxHeight = '100%'
      } else {
        appliedStyles.maxHeight = tokens.height[maxHeight]
      }
    }

    if (borderRadius) {
      if (borderRadius === 'max') {
        appliedStyles.borderRadius = '50%'
      } else {
        appliedStyles.borderRadius = tokens.borderRadius[borderRadius]
      }
    }

    if (transformations?.ar) {
      const [width, height] = transformations.ar
        .split('-')
        .map((dim) => parseInt(dim, 10))

      appliedStyles.aspectRatio = width / height
    }

    return appliedStyles
  }, [
    borderRadius,
    imageDimensions,
    maxHeight,
    maxWidth,
    tokens,
    transformations,
  ])

  const handleLayout = useCallback(
    (event: LayoutChangeEvent) => {
      if (isMounted()) {
        setLayoutDimensions(event.nativeEvent.layout)
      }
    },
    [isMounted]
  )

  useEffect(() => {
    let isInEffect = true

    Image.getSize(imageKitURL, (width, height) => {
      if (isInEffect) {
        setImageDimensions({ width, height })
      }
    })

    return () => {
      isInEffect = false
    }
  }, [imageKitURL, setImageDimensions])

  return {
    handleLayout,
    imageKitURL,
    styles,
  }
}

/**
 * For rendering image kit images with url string
 */
export const ImageKitImage: FC<ImageKitImageProps> = (props) => {
  const { as, children, component, onLoad, variant, style, ...imageKitProps } =
    props

  const { handleLayout, imageKitURL, styles } = useImageKit(imageKitProps)

  return (
    <View onLayout={handleLayout}>
      <Element
        as={as ?? Image}
        children={children}
        component={component || 'GraphQLImage'}
        onLoad={onLoad}
        source={{ uri: imageKitURL }}
        style={styles}
        variant={variant}
      />
    </View>
  )
}

/**
 * For rendering image kit images with GraphQLImage
 */
export const GraphQLImage: FC<GraphQLImageProps> = (props) => {
  const { image, ...imageKitImageProps } = props
  const data = useFragment(
    graphql`
      fragment GraphQLImage_image on Image {
        url
      }
    `,
    image
  )

  return <ImageKitImage imageUrl={data.url} {...imageKitImageProps} />
}

const createImageKitURL = (
  baseURL: string,
  tokens: TokenValues,
  options: CreateImageKitURLOptions = {}
): string => {
  const transformations = options.transformations || {}

  if (options.focus) {
    transformations['fo'] = options.focus
  }

  if (options.maxWidth) {
    if (options.maxWidth === 'max') {
      if (options.layoutDimensions?.width) {
        transformations['w'] = PixelRatio.getPixelSizeForLayoutSize(
          Math.ceil(options.layoutDimensions?.width)
        )
      }
    } else {
      transformations['w'] = PixelRatio.getPixelSizeForLayoutSize(
        tokens.width?.[options.maxWidth]
      )
    }
  }

  if (options.maxHeight) {
    if (options.maxHeight === 'max') {
      if (options.layoutDimensions?.height) {
        transformations['h'] = PixelRatio.getPixelSizeForLayoutSize(
          Math.ceil(options.layoutDimensions?.height)
        )
      }
    } else {
      transformations['h'] = PixelRatio.getPixelSizeForLayoutSize(
        tokens.height[options.maxHeight]
      )
    }
  }

  if (options.borderRadius) {
    if (typeof options.borderRadius === 'number') {
      transformations['r'] = tokens.borderRadius[options.borderRadius * 2]
    }

    transformations['r'] = options.borderRadius
  }

  const transformationOptions = Object.entries(transformations)
    .map(([transformation, value]) => {
      return `${transformation}-${value}`
    })
    .join(',')

  if (transformationOptions) {
    return `${baseURL}?tr=${transformationOptions}`
  }

  return baseURL
}
