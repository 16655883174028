import React, { FC } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentPerson: FC<{ fillColor?: string } & SvgProps> = ({
  fillColor = '#212121',
  ...props
}) => (
  <Svg
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M17.754 14a2.249 2.249 0 012.25 2.249v.575c0 .894-.32 1.76-.902 2.438-1.57 1.834-3.957 2.739-7.102 2.739-3.146 0-5.532-.905-7.098-2.74a3.75 3.75 0 01-.898-2.435v-.577a2.249 2.249 0 012.249-2.25h11.501zm0 1.5H6.253a.749.749 0 00-.75.749v.577c0 .536.192 1.054.54 1.461 1.253 1.468 3.219 2.214 5.957 2.214s4.706-.746 5.962-2.214a2.25 2.25 0 00.541-1.463v-.575a.749.749 0 00-.749-.75zM12 2.004a5 5 0 110 10 5 5 0 010-10zm0 1.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7z'
      fill={fillColor}
    />
  </Svg>
)
export default FluentPerson
