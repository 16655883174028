import React, {
  FC,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from 'react'
import {
  ActivityIndicator,
  GestureResponderEvent,
  Pressable,
} from 'react-native'

import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  Easing,
} from 'react-native-reanimated'

import { Element } from './Element'
import { StackProps } from './Stack'
import { Icon } from './Icon'

import { useIsMounted } from './useIsMounted'

import { ComponentsContext, TokensContext, BreakpointContext } from './context'
import { applyStyles } from './util/applyStyles'

interface ButtonProps {
  onPress?: (event: GestureResponderEvent) => Promise<any> | any
  activeOpacity?: number
  justifyContent?: StackProps['justifyContent']
  alignItems?: StackProps['alignItems']

  variant?: string
  hoverVariant?: string
  successVariant?: string
  errorVariant?: string

  loadingSpinnerSize?: number

  onHoverIn?: () => void
  onHoverOut?: () => void
}

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

export const Button = ({
  variant,
  onPress,
  activeOpacity,
  children,

  alignItems = 'center',
  justifyContent = 'center',

  hoverVariant,
  successVariant,
  errorVariant,

  loadingSpinnerSize = 20,

  onHoverIn,
  onHoverOut,
}: ButtonProps & { children: React.ReactNode }) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [flashSuccess, setFlashSuccess] = useState(false)
  const [flashError, setFlashError] = useState(false)
  const isMounted = useIsMounted()
  const tokensContext = useContext(TokensContext)
  const componentsContext = useContext(ComponentsContext)
  const breakpointContext = useContext(BreakpointContext)

  useEffect(() => {
    if (!successVariant && flashSuccess) {
      setFlashSuccess(false)
    }
  }, [successVariant, flashSuccess, setFlashSuccess])

  const computedVariant = useMemo(() => {
    if (successVariant && flashSuccess) {
      return successVariant
    }

    if (errorVariant && flashError) {
      return errorVariant
    }

    if (isHovered && hoverVariant) {
      return hoverVariant
    }

    return variant
  }, [
    variant,
    successVariant,
    errorVariant,
    flashSuccess,
    flashError,
    hoverVariant,
    isHovered,
  ])

  const buttonStyle = useMemo(() => {
    return applyStyles(
      tokensContext,
      componentsContext,
      breakpointContext,
      {},
      'Button',
      computedVariant
    )
  }, [tokensContext, componentsContext, breakpointContext, computedVariant])

  const pressOpacity = useSharedValue(1.0)

  const buttonColor0Animation = useSharedValue(
    buttonStyle.backgroundColor || buttonStyle.linearGradientColorsFrom
  )
  const buttonColor1Animation = useSharedValue(
    buttonStyle.backgroundColor || buttonStyle.linearGradientColorsTo
  )
  const borderColorAnimation = useSharedValue(buttonStyle.borderColor)

  useEffect(() => {
    if (buttonStyle.backgroundColor) {
      buttonColor0Animation.value = withTiming(buttonStyle.backgroundColor, {
        duration: 250,
        easing: Easing.bezier(0, 0.5, 1, 1),
      })
      buttonColor1Animation.value = withTiming(buttonStyle.backgroundColor, {
        duration: 250,
        easing: Easing.bezier(0, 0.5, 1, 1),
      })
    } else {
      buttonColor0Animation.value = withTiming(
        buttonStyle.linearGradientColorsFrom,
        { duration: 250, easing: Easing.bezier(0, 0.5, 1, 1) }
      )
      buttonColor1Animation.value = withTiming(
        buttonStyle.linearGradientColorsTo,
        { duration: 250, easing: Easing.bezier(0, 0.5, 1, 1) }
      )
    }

    borderColorAnimation.value = withTiming(buttonStyle.borderColor, {
      duration: 250,
      easing: Easing.bezier(0, 0.5, 1, 1),
    })
  }, [
    buttonStyle.linearGradientColorsFrom,
    buttonStyle.linearGradientColorsTo,
    buttonStyle.backgroundColor,
    buttonStyle.borderColor,
  ])

  const handlePress = useCallback(
    async (event) => {
      // Prevent multiple clicks
      if (isLoading) {
        return
      }

      if (onPress) {
        try {
          setIsLoading(true)
          setFlashSuccess(false)
          setFlashError(false)

          await onPress(event)

          if (!isMounted()) {
            return
          }

          setIsLoading(false)

          if (successVariant) {
            setFlashSuccess(true)

            const timeoutId = setTimeout(() => {
              if (isMounted()) {
                setFlashSuccess(false)
              }
            }, 2000)

            return () => {
              clearTimeout(timeoutId)
            }
          }
        } catch (err) {
          if (errorVariant) {
            setFlashError(true)

            const timeoutId = setTimeout(() => {
              if (isMounted()) {
                setFlashError(false)
              }
            }, 2000)

            return () => {
              clearTimeout(timeoutId)
            }
          }

          throw err
        } finally {
          if (isMounted()) {
            setIsLoading(false)
          }
        }
      }
    },
    [
      isLoading,
      onPress,
      setIsLoading,
      isMounted,
      successVariant,
      errorVariant,
      setFlashSuccess,
      setFlashError,
    ]
  )

  const handlePressIn = useCallback(() => {
    pressOpacity.value = withTiming(0.4, { duration: 100 })
  }, [])

  const handlePressOut = useCallback(() => {
    pressOpacity.value = withTiming(1.0, { duration: 100 })
  }, [])

  const handleHoverIn = useCallback(() => {
    if (hoverVariant) {
      setIsHovered(true)
    }

    if (onHoverIn) {
      onHoverIn()
    }
  }, [onHoverIn, setIsHovered, hoverVariant])

  const handleHoverOut = useCallback(() => {
    if (hoverVariant) {
      setIsHovered(false)
    }

    if (onHoverOut) {
      onHoverOut()
    }
  }, [onHoverOut, hoverVariant])

  const buttonContent = useMemo(() => {
    if (typeof children === 'function') {
      return children({ isLoading, isSuccess: flashSuccess })
    }

    if (flashSuccess) {
      return <Icon name='checkmark-filled' variant='button' size={4} />
    }

    if (flashError) {
      return <Icon name='dismiss-filled' variant='button' size={4} />
    }

    if (isLoading) {
      return (
        <ActivityIndicator
          color={buttonStyle.loadingColor}
          size='small'
          style={{ width: loadingSpinnerSize, height: loadingSpinnerSize }}
        />
      )
    }

    return children
  }, [isLoading, buttonStyle.loadingColor, flashSuccess, flashError, children])

  const animatedStyle = useAnimatedStyle(() => {
    const { backgroundColor, borderColor, opacity, ...remainder } = buttonStyle

    return {
      alignItems,
      justifyContent,
      ...remainder,
      background: `linear-gradient(${buttonColor0Animation.value}, ${buttonColor1Animation.value})`,
      borderColor: borderColorAnimation.value,
      opacity: pressOpacity.value,
    }
  }, [buttonStyle])

  return (
    <AnimatedPressable
      style={animatedStyle}
      onPress={handlePress}
      onPressIn={handlePressIn}
      onPressOut={handlePressOut}
      onHoverIn={handleHoverIn}
      onHoverOut={handleHoverOut}
    >
      {buttonContent}
    </AnimatedPressable>
  )
}
