import React, { useMemo, forwardRef } from 'react'
import type { StyleProp } from 'react-native'

// import { createIconSet } from '@expo/vector-icons'

import loadable from '@loadable/component'

// import materialCommunityFont from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
// import fontAwesomeFont from '@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf'

// import { materialCommunityGlyphMap } from './materialCommunityGlyphMap'
// import { fontAwesomeGlyphMap } from './fontAwesomeGlyphMap'

import FluentAddIcon from './fluentIcons/add'
import FluentPersonIcon from './fluentIcons/person'
import FluentNavigationIcon from './fluentIcons/navigation'

const FluentAddFilled = loadable(() => import('./fluentIcons/add-filled'))
const FluentGlobeIcon = loadable(() => import('./fluentIcons/globe'))
const FluentHeartIcon = loadable(() => import('./fluentIcons/heart'))
const FluentStarIcon = loadable(() => import('./fluentIcons/star'))
const FluentAlertIcon = loadable(() => import('./fluentIcons/alert'))
const FluentArrowCircleRightIcon = loadable(
  () => import('./fluentIcons/arrow-circle-right')
)
const FluentArrowCircleUpIcon = loadable(
  () => import('./fluentIcons/arrow-circle-up')
)
const FluentArrowLeftIcon = loadable(() => import('./fluentIcons/arrow-left'))
const FluentArrowRedoIcon = loadable(() => import('./fluentIcons/arrow-redo'))
const FluentArrowResetIcon = loadable(() => import('./fluentIcons/arrow-reset'))
const FluentArrowRightIcon = loadable(() => import('./fluentIcons/arrow-right'))
const FluentArrowDownFilled = loadable(
  () => import('./fluentIcons/arrow-down-filled')
)
const FluentArrowUndoIcon = loadable(() => import('./fluentIcons/./arrow-undo'))
const FluentArrowUpIcon = loadable(() => import('./fluentIcons/arrow-up'))
const CustomBuildingDesktopIcon = loadable(
  () => import('./fluentIcons/building-desktop')
)
const FluentBuildingIcon = loadable(() => import('./fluentIcons/building'))
const FluentCalendarAddFilledIcon = loadable(
  () => import('./fluentIcons/calendar-add-filled')
)
const FluentCalendarIcon = loadable(() => import('./fluentIcons/calendar'))
const FluentCalendarMultiple = loadable(
  () => import('./fluentIcons/calendar-multiple')
)
const FluentCheckmarkIcon = loadable(() => import('./fluentIcons/checkmark'))
const FluentCheckmarkFilledIcon = loadable(
  () => import('./fluentIcons/checkmark-filled')
)
const FluentChevronDownIcon = loadable(
  () => import('./fluentIcons/chevron-down')
)
const FluentChevronRightIcon = loadable(
  () => import('./fluentIcons/chevron-right')
)
const FluentChevronUpIcon = loadable(() => import('./fluentIcons/chevron-up'))
const FluentClockIcon = loadable(() => import('./fluentIcons/clock'))
const FluentCopyIcon = loadable(() => import('./fluentIcons/copy'))
const FluentDesktopIcon = loadable(() => import('./fluentIcons/desktop'))
const FluentDismissIcon = loadable(() => import('./fluentIcons/dismiss'))
const FluentDismissFilledIcon = loadable(
  () => import('./fluentIcons/dismiss-filled')
)
const FluentDoorArrowLeft = loadable(
  () => import('./fluentIcons/door-arrow-left')
)
const FluentDoorArrowLeftFilled = loadable(
  () => import('./fluentIcons/door-arrow-left-filled')
)
const FluentEditIcon = loadable(() => import('./fluentIcons/edit'))
const FluentErrorCircle = loadable(() => import('./fluentIcons/error-circle'))
const FluentGlobeClockIcon = loadable(() => import('./fluentIcons/globe-clock'))
const FluentGlobeFilledIcon = loadable(
  () => import('./fluentIcons/globe-filled')
)
const FluentGlobeLocationIcon = loadable(
  () => import('./fluentIcons/globe-location')
)
const FluentHeartFilledIcon = loadable(
  () => import('./fluentIcons/heart-filled')
)
const FluentHomePersonIcon = loadable(() => import('./fluentIcons/home-person'))
const FluentHomeIcon = loadable(() => import('./fluentIcons/home'))
const FluentHomeFilledIcon = loadable(() => import('./fluentIcons/home-filled'))
const FluentLinkIcon = loadable(() => import('./fluentIcons/link'))
const FluentLocationIcon = loadable(() => import('./fluentIcons/location'))
const FluentLockClosedIcon = loadable(() => import('./fluentIcons/lock-closed'))
const FluentMailInboxFilledIcon = loadable(
  () => import('./fluentIcons/mail-inbox-filled')
)
const FluentMailInboxIcon = loadable(() => import('./fluentIcons/mail-inbox'))
const FluentMailIcon = loadable(() => import('./fluentIcons/mail'))
const FluentMicOnFilledIcon = loadable(
  () => import('./fluentIcons/mic-on-filled')
)
const FluentMicSparkleFilledIcon = loadable(
  () => import('./fluentIcons/mic-sparkle-filled')
)

const FluentMoneyIcon = loadable(() => import('./fluentIcons/money'))

const FluentOrganizationIcon = loadable(
  () => import('./fluentIcons/organization')
)
const FluentPeopleCommunityIcon = loadable(
  () => import('./fluentIcons/people-community')
)
const FluentPeopleIcon = loadable(() => import('./fluentIcons/people'))

const FluentPlayIcon = loadable(() => import('./fluentIcons/play'))
const FluentPresenterFilledIcon = loadable(
  () => import('./fluentIcons/presenter-filled')
)
const FluentProjectionScreenIcon = loadable(
  () => import('./fluentIcons/projection-screen')
)
const FluentRibbonFilledIcon = loadable(
  () => import('./fluentIcons/ribbon-filled')
)
const FluentSettingsFilledIcon = loadable(
  () => import('./fluentIcons/settings-filled')
)
const FluentSignOutFilledIcon = loadable(
  () => import('./fluentIcons/sign-out-filled')
)
const FluentSettingsIcon = loadable(() => import('./fluentIcons/settings'))
const FluentStarFilledIcon = loadable(() => import('./fluentIcons/star-filled'))
const FluentSlideMicrophone = loadable(
  () => import('./fluentIcons/slide-microphone')
)
const FluentTextBoldIcon = loadable(() => import('./fluentIcons/text-bold'))
const FluentTextBulletListLtrIcon = loadable(
  () => import('./fluentIcons/text-bullet-list-ltr')
)
const FluentTextHeader1Icon = loadable(
  () => import('./fluentIcons/text-header1')
)
const FluentTextHeader2Icon = loadable(
  () => import('./fluentIcons/text-header2')
)
const FluentTextHeader3Icon = loadable(
  () => import('./fluentIcons/text-header3')
)
const FluentTextItalicIcon = loadable(() => import('./fluentIcons/text-italic'))
const FluentTextNumberListLtrIcon = loadable(
  () => import('./fluentIcons/text-number-list-ltr')
)
const FluentTextParagraphIcon = loadable(
  () => import('./fluentIcons/text-paragraph')
)
const FluentTextIcon = loadable(() => import('./fluentIcons/text'))
const FluentTicketDiagonal = loadable(
  () => import('./fluentIcons/ticket-diagonal')
)
const FluentTicketDiagonalFilled = loadable(
  () => import('./fluentIcons/ticket-diagonal-filled')
)
const FluentTicketHorizontalIcon = loadable(
  () => import('./fluentIcons/ticket-horizontal')
)
const FluentTicketHorizontalFilledIcon = loadable(
  () => import('./fluentIcons/ticket-horizontal-filled')
)
const FluentVideoPersonSparkle = loadable(
  () => import('./fluentIcons/video-person-sparkle')
)
const FluentWandFilledIcon = loadable(() => import('./fluentIcons/wand-filled'))
const FluentWandIcon = loadable(() => import('./fluentIcons/wand'))

const FluentWarningFilledIcon = loadable(
  () => import('./fluentIcons/warning-filled')
)

const FluentWindowNew = loadable(() => import('./fluentIcons/window-new'))

const FontAwesomeMeetup = loadable(() => import('./fontAwesomeIcons/meetup'))

const MaterialCommunityLanguageMarkdown = loadable(
  () => import('./materialCommunityIcons/language-markdown')
)

const MaterialCommunityLinkedIn = loadable(
  () => import('./materialCommunityIcons/linkedin')
)

const MaterialCommunitySlack = loadable(
  () => import('./materialCommunityIcons/slack')
)

const MaterialCommunityTwitter = loadable(
  () => import('./materialCommunityIcons/twitter')
)

const MaterialCommunityYouTube = loadable(
  () => import('./materialCommunityIcons/youtube')
)

const FONT_AWESOME_ICONS = {
  meetup: FontAwesomeMeetup,
}

const MATERIAL_COMMUNITY_ICONS = {
  'language-markdown': MaterialCommunityLanguageMarkdown,
  linkedin: MaterialCommunityLinkedIn,
  slack: MaterialCommunitySlack,
  twitter: MaterialCommunityTwitter,
  youtube: MaterialCommunityYouTube,
}

const REACT_FLUENT_ICONS = {
  'add-filled': FluentAddFilled,
  'arrow-circle-right': FluentArrowCircleRightIcon,
  'arrow-circle-up': FluentArrowCircleUpIcon,
  'arrow-down-filled': FluentArrowDownFilled,
  'arrow-left': FluentArrowLeftIcon,
  'arrow-redo': FluentArrowRedoIcon,
  'arrow-reset': FluentArrowResetIcon,
  'arrow-right': FluentArrowRightIcon,
  'arrow-undo': FluentArrowUndoIcon,
  'arrow-up': FluentArrowUpIcon,
  'calendar-add-filled': FluentCalendarAddFilledIcon,
  'calendar-multiple': FluentCalendarMultiple,
  'checkmark-filled': FluentCheckmarkFilledIcon,
  'chevron-down': FluentChevronDownIcon,
  'chevron-right': FluentChevronRightIcon,
  'chevron-up': FluentChevronUpIcon,
  'dismiss-filled': FluentDismissFilledIcon,
  'door-arrow-left-filled': FluentDoorArrowLeftFilled,
  'door-arrow-left': FluentDoorArrowLeft,
  'error-circle': FluentErrorCircle,
  'globe-clock': FluentGlobeClockIcon,
  'globe-filled': FluentGlobeFilledIcon,
  'globe-location': FluentGlobeLocationIcon,
  'heart-filled': FluentHeartFilledIcon,
  'home-filled': FluentHomeFilledIcon,
  'home-person': FluentHomePersonIcon,
  'lock-closed': FluentLockClosedIcon,
  'mail-inbox-filled': FluentMailInboxFilledIcon,
  'mail-inbox': FluentMailInboxIcon,
  'mic-on-filled': FluentMicOnFilledIcon,
  'mic-sparkle-filled': FluentMicSparkleFilledIcon,
  'people-community': FluentPeopleCommunityIcon,
  'presenter-filled': FluentPresenterFilledIcon,
  'projection-screen': FluentProjectionScreenIcon,
  'ribbon-filled': FluentRibbonFilledIcon,
  'settings-filled': FluentSettingsFilledIcon,
  'sign-out-filled': FluentSignOutFilledIcon,
  'slide-microphone': FluentSlideMicrophone,
  'star-filled': FluentStarFilledIcon,
  'text-bold': FluentTextBoldIcon,
  'text-bullet-list-ltr': FluentTextBulletListLtrIcon,
  'text-header1': FluentTextHeader1Icon,
  'text-header2': FluentTextHeader2Icon,
  'text-header3': FluentTextHeader3Icon,
  'text-italic': FluentTextItalicIcon,
  'text-number-list-ltr': FluentTextNumberListLtrIcon,
  'text-paragraph': FluentTextParagraphIcon,
  'ticket-diagonal-filled': FluentTicketDiagonalFilled,
  'ticket-diagonal': FluentTicketDiagonal,
  'ticket-horizontal-filled': FluentTicketHorizontalFilledIcon,
  'ticket-horizontal': FluentTicketHorizontalIcon,
  'video-person-sparkle': FluentVideoPersonSparkle,
  'wand-filled': FluentWandFilledIcon,
  'warning-filled': FluentWarningFilledIcon,
  'window-new': FluentWindowNew,
  add: FluentAddIcon,
  alert: FluentAlertIcon,
  building: FluentBuildingIcon,
  buildingDesktop: CustomBuildingDesktopIcon,
  calendar: FluentCalendarIcon,
  checkmark: FluentCheckmarkIcon,
  clock: FluentClockIcon,
  copy: FluentCopyIcon,
  desktop: FluentDesktopIcon,
  dismiss: FluentDismissIcon,
  edit: FluentEditIcon,
  globe: FluentGlobeIcon,
  heart: FluentHeartIcon,
  home: FluentHomeIcon,
  link: FluentLinkIcon,
  location: FluentLocationIcon,
  mail: FluentMailIcon,
  money: FluentMoneyIcon,
  navigation: FluentNavigationIcon,
  organization: FluentOrganizationIcon,
  people: FluentPeopleIcon,
  person: FluentPersonIcon,
  play: FluentPlayIcon,
  settings: FluentSettingsIcon,
  star: FluentStarIcon,
  text: FluentTextIcon,
  wand: FluentWandIcon,
}

export type GuildIconKeys =
  | keyof typeof REACT_FLUENT_ICONS
  | keyof typeof FONT_AWESOME_ICONS
  | keyof typeof MATERIAL_COMMUNITY_ICONS

export const GuildIcon = forwardRef(
  ({ name, ...props }: { name: GuildIconKeys; color?: string }, ref) => {
    return (
      <ReactNativeSVGIcon
        ref={ref}
        IconComponent={
          REACT_FLUENT_ICONS[name] ||
          FONT_AWESOME_ICONS[name] ||
          MATERIAL_COMMUNITY_ICONS[name]
        }
        {...props}
      />
    )
  }
)

const ReactNativeSVGIcon = forwardRef(
  (
    {
      IconComponent,
      style,
      ...props
    }: {
      IconComponent: React.ComponentType<any>

      style?: StyleProp<any>
    },
    ref
  ) => {
    const fillColor = useMemo(() => {
      if (style) {
        if (style.color) {
          return style.color
        }

        if (Array.isArray(style) && style[0].color) {
          return style[0].color
        }
      }

      return
    }, [style?.color])

    const passedProps = useMemo(() => {
      let width, height

      if (style) {
        if (style.width) {
          width = style.width
        }

        if (style.height) {
          height = style.height
        }

        if (Array.isArray(style) && style[0]) {
          if (style[0].width) {
            width = style[0].width
          }

          if (style[0].height) {
            height = style[0].height
          }
        }
      }

      return {
        ...props,
        style,
        fillColor,
        width,
        height,
        ref,
      }
    }, [props, style, fillColor, ref])

    if (!IconComponent) {
      return null
    }

    return <IconComponent {...passedProps} />
  }
)
