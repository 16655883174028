import React, { FC, ComponentProps, useContext } from 'react'

import { GuildIcon } from './GuildIcon/GuildIcon'
import { TokensContext } from './context'
import { Element } from './Element'

export type IconName = ComponentProps<typeof GuildIcon>['name']
type IconProps = {
  name: IconName
  size?: number
  testID?: string
  variant?: string
}

export const Icon: FC<IconProps> = ({ name, size = 5, testID, variant }) => {
  const tokensContext = useContext(TokensContext)

  return (
    <Element
      component='Icon'
      variant={variant}
      as={GuildIcon}
      name={name}
      size={tokensContext.size[size]}
      tokens={{ width: size, height: size, minWidth: size, minHeight: size }}
      testID={testID}
    />
  )
}
