import React, { FC } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const FluentNavigation: FC<{ fillColor?: string } & SvgProps> = ({
  fillColor = '#212121',
  ...props
}) => (
  <Svg
    width={24}
    height={24}
    fill='none'
    viewBox='0 0 24 24'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <Path
      d='M3 17h18a1 1 0 01.117 1.993L21 19H3a1 1 0 01-.117-1.993L3 17h18H3zm0-6l18-.002a1 1 0 01.117 1.993l-.117.007L3 13a1 1 0 01-.117-1.993L3 11l18-.002L3 11zm0-6h18a1 1 0 01.117 1.993L21 7H3a1 1 0 01-.117-1.993L3 5h18H3z'
      fill={fillColor}
    />
  </Svg>
)
export default FluentNavigation
